// @import '~@angular/material/theming';
// @include mat-core();
// $development-primary: mat-palette($mat-indigo);
// $development-accent: mat-palette($mat-pink, A200, A100, A400);
// $development-warn: mat-palette($mat-red);
// $development-theme: mat-light-theme($development-primary, $development-accent, $development-warn);
// @include angular-material-theme($development-theme);

@import 'helpers';
@import 'shapes';
@import 'fonts';
@import 'effects';
@import 'api-loading';
@import 'custom-material';

.husk-color {
  color: $husk;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.body-article p {
  margin-bottom: 20px;
}

.instafeed-container {
  width: 100%;
  margin: 0 auto;

  @include respond(gt-xs) {
    width: 80%;
  }

  .insta-title {
    width: 100%;

    h5 {
      line-height: 30px;
      font-weight: 700;
      color: $white;
    }
  }

  .instafeed {
    display: flex;
    align-items: center;
    place-content: space-around;
    text-align: center;
    flex-direction: column;
    overflow: hidden;
    max-width: 100vw;

    @include respond(gt-xs) {
      flex-direction: row;
    }

    > a {
      margin-bottom: 3%;
      display: inline-block;

      @include respond(gt-xs) {
        margin-right: 5px;
      }

      div {
        img {
          width: 100%;
        }

        @include respond(gt-xs) {
          img {
            height: 160px;
            width: 160px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.project-logo {
  height: 55px;
  margin: 0;
}

.top-project-bar {
  position: relative;
  min-height: 60px;

  a {
    position: relative;
    z-index: 10;
  }

  > span {
    max-width: 350px;
  }

  h1 {
    @include respond(gt-sm) {
      position: absolute;
      width: 100%;
    }
  }
}

.news p a {
  color: $green;
  text-decoration: underline;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

iframe {
  width: 100%;
  height: 100%;
}

.mat-tab-group {
  font-family: $body-font;
}

body {
  min-height: 100%;
  font-family: $body-font;
  font-size: 1.6rem;
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: $black;
  color: $white;

  &.no-scroll {
    overflow: hidden;
  }

  p {
    font-family: $font-bask;
  }

  .qoute-text {
    font-family: $headers-font !important;
  }
}

app-root {
  display: flex;
  flex-direction: column;
  position: relative;

  .site-content {
    position: relative;
    // padding-top: 50px;

    // @include respond(gt-sm) {
    //   padding-top: 95px;
    // }
  }

  router-outlet~* {
    display: flex;
    flex-direction: column;
  }
}

#{headings(1, 6)} {
  margin: 0;
  font-family: $headers-font;
  font-weight: normal;
}

h1 {
  font-weight: bold;
}

h2 {
  font-size: 3rem;
  font-weight: bold;
}

h3 {
  font-size: 2.3rem;
  font-weight: bold;
}

h4 {
  font-size: 1.8rem;
}

ul {
  list-style-type: none;
}

ul.disc {
  list-style-type: disc;
  padding-inline-start: 20px;
  margin-top: 5px;
  margin-bottom: 5px;

  li {
    line-height: 1.2;
    margin-bottom: 5px;
  }
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  line-height: 24px;
  text-align: justify;
}

.text-block p {
  margin-bottom: 10px;
}

button {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none;
}

.container {
  width: 100%;
  max-width: 1105px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.quote.bold {
  font-weight: bold;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.mx-2 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.pb-5 {
  padding-bottom: 5rem;
}

.pt-5 {
  padding-top: 5rem;
}

.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-5 {
  padding-right: 5rem;
  padding-left: 5rem;
}

.pl-0 {
  padding-left: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.nohovereffect {
  &:hover {
    background-color: transparent !important;
  }
}

.hero {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
  @include respond(gt-sm) {
    min-height: 750px;
  }

  .overlay {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  p {
    font-size: 1.6rem;
  }

  blockquote {
    &::before {
      content: '\f10d';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
    }
  }
}

img.gear {
  width: 100px !important;
}

.hr-top-right {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 1px;
    background-color: $white;
  }
}

.hr-top-left {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 1px;
    background-color: $white;
  }
}

.quote {
  padding: 30px;
  line-height: 32px;
  font-size: 1.8rem;
  text-align: center;
  position: relative;

  &:not(.no-icon)::after {
    // content: '“';
    font-size: 15rem;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-65%);
  }

  @include respond(gt-sm) {
    padding: 30px 70px;
  }
}

//cta

.cta {
  position: relative;
  padding-top: 6rem;

  >div {
    margin: auto;
  }

  p {
    text-align: center;
    font-size: 1.8rem;
    line-height: 1.4;
  }

  hr {
    width: 25%;
  }
}

.section-title {
  text-align: center;

  h1 {
    font-size: 5rem;
  }

  label {
    display: block;
    font-size: 1.4rem;
  }
}

.history-body {

  >div:first-child {
    order: 2;
    margin-top: 100px;

    @include respond(gt-xs) {
      margin-top: 0;
      order: 0;
    }
  }

  p {
    line-height: 24px;
  }

  .img-wrapper {
    @include bg-cover(cover);

    width: 100%;
    height: 320px;
    @include respond(gt-xs) {
      height: 100%;
    }
    position: relative;

    &::before {
      content: '';
      width: calc(100% + 30px);
      height: 200px;
      background-color: $bkg-highlight;
      position: absolute;
      bottom: -85px;
      left: -60px;
      z-index: -1;
    }
  }
}

.projects-list {
  .history-body {
    &:nth-child(even) {
      >div:first-child {
        order: 2;
      }
    }

    +.history-body {
      margin-top: 70px;
      @include respond(gt-xs) {
        margin-top: 150px;
        margin-bottom: 150px;
      }
    }

    p+a {
      margin-top: 40px;
    }

    p+label {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    label {
      font-size: 2.4rem;
      font-family: $headers-font;
    }

    >div:first-child {
      display: flex;
      flex-direction: column;

      a {
        align-self: flex-start;
      }
    }
  }
}

.border-bottom-right {
  border-bottom: 1px solid $white;
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 60px);
}

.pos-relative {
  position: relative;
}

.qoute-text {
  text-transform: uppercase;
  text-align: center;
  font-size: 2.8rem;
  line-height: 1.4;
}

.qoute {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.8;
}

// *** TO clean up between float and flex
.special-letter-wrapper {
  position: relative;
  display: inline-block;
}

.special-letter {
  font-size: 2em;
  position: absolute;
  top: 50%;

  &.left {
    left: 0%;
    transform: translate(-110%, -60%);
  }

  &.right {
    transform: translate(110%, -75%);
    right: 0%;
  }
}

h1.special-letter-wrapper,
h2.special-letter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .special-letter {
    position: static;
    transform: translate(0, -8%);
  }
}

h1.special-letter-wrapper {
  .special-letter {
    @include respond(gt-sm) {
      transform: translate(0, -20%);
    }
  }
}

// TO clean up between float and flex ***

.scroll-to-top {
  height: 50px;
  width: 50px;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $black;
  color: $white;
  position: fixed;
  left: 12px;
  bottom: 12px;
  border: 2px solid $white;
  opacity: .7;
  transition: opacity .3s ease-in-out,
    border-radius .3s ease-in-out;
  cursor: pointer;
  padding: 5px;

  svg {
    height: 100%;
    width: 100%;
  }

  &:hover {
    opacity: 1;
    border-radius: 50%;
  }

  &.show {
    display: none;
  }

  .material-icons {
    font-size: 36px;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.c-husk {
   color: $husk !important;
}

//hagag hero
.hero-sec {
  width: 100%;
  position: relative;
  overflow: hidden;

  @include respond('till-sm') {
      margin-bottom: 20px;
  }

  @include respond(gt-sm) {
    min-height: 700px;
    padding-top: 0;
  }

  @include respond(gt-lg) {
    max-height: initial;
  }

  .mat-tab-body.mat-tab-body-active {
    z-index: inherit !important;
  }

  .slider-bullets {
    display: none !important;
  }

  .img-hero {
    max-width: 100%;
    height: auto;
    @include respond(gt-xs) {
      width: 100%;
    }
  }

  &.full {
    padding-top: 0;

    @include respond (gt-sm) {
      max-height: 1000px;
    }
  }

  .mat-tab-body-wrapper {
    transition: none;
  }

  .hero-lead {
    margin: 0 auto;
    width: 90%;
    border: 1px solid $husk;
    box-shadow: 0 2px 10px 0 rgba($shadow, .996);
    background-color: rgba($black, .71);
    padding: 30px 10px;

    @include respond (gt-sm) {
      width: 400px;
      right: 17%;
      top: 50%;
      left: auto;
      z-index: 2;
      padding: 60px 25px;

      position: absolute;
      transform: translateY(-50%);
    }

    .title {
      h1 {
        font-family: 'Arial';
        font-size: 24px;
        line-height: 30px;
        color: $husk;
        font-weight: 400;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        text-transform: none;
      }
    }

    .lead-footer {
      margin: 0 auto;
    }

    .lead-header {
      background: $husk;
      height: 43px;
      width: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .btn {
      margin-top: 20px;
      width: max-content;

      span {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .section-scroll1 {
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 2%;
    display: none;

    @include respond(gt-xs) {
      display: flex;
    }

    @include respond(gt-sm) {
      height: 200px;
      bottom: 0;
    }

    .hscroll-line {
      bottom: 50px;
      right: 50%;
      height: 45px;
      position: absolute;
      overflow: hidden;
      width: 1px;

      @include respond(till-sm) {
        display: none;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: $black;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: $husk;
        animation: move 3s infinite;
      }

      @keyframes move {
        0% {
          transform: translate3d(0, -200%, 0);
        }

        60% {
          transform: translate3d(0, 100%, 0);
        }

        100% {
          transform: translate3d(0, 100%, 0);
        }
      }
    }

    .scroller {
      width: 35px;
      height: 55px;
      position: absolute;
      left: 50%;
      top: 40px;
      transform: translateX(-50%);
      cursor: pointer;

      @include respond (gt-sm) {
        bottom: 0;
      }
    }

    .mouse {
      width: 100%;
      box-sizing: border-box;
      height: 100%;
      border: 1px solid;
      border-radius: 35px;
      position: relative;
      animation: mouseAnim 1s linear infinite alternate;
    }

    .ball {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 4px;
      height: 4px;
      background: $husk;
      animation: ballAnim 1s linear infinite;
    }

    @keyframes ballAnim {
      0% {
        width: 3px;
        height: 3px;
        border-radius: 5px;
        opacity: 0;
        top: 10px;
      }

      50% {
        width: 4px;
        height: 4px;
        border-radius: 6px;
        opacity: 1;
        top: 30px;
      }

      100% {
        width: 4px;
        height: 4px;
        border-radius: 4px;
        opacity: 0;
        top: 53px;
      }
    }

    @keyframes mouseAnim {
        0% {
          border-color: $husk;
          opacity: 1;
        }

        50% {
          border-color: $husk;
          opacity: .8;
        }

        100% {
          border-color: $husk;
          opacity: .5;
        }
    }
  }
}

//social-bar

.social-bar {
  @include box-shadow (0 0 10px 0 rgba($black, .3));
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 9;
  @include respond(gt-md) {
    top: 0;
    left: 0;
    height: 100vh;
    width: auto !important;
  }

  ul {
    background-color: $white;
    color: $black;
    text-align: center;
    padding: 10px 5px;
    height: 100%;

    li {
      @include respond (gt-md) {
        padding-bottom: 10px;
      }

      position: relative;

      img {
        width: 20px;

        @include respond (gt-md) {
          width: 30px;
        }
      }

      &.developed-by-h {
        img {
          width: 50px;
        }
      }
    }

    @include respond (gt-md) {
      .last {
        padding-bottom: 100px;
      }

      .first {
        padding-top: 100px;
      }
    }
  }
}

.v-block-md {
  font-size: 8px;

  @include respond(gt-md) {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  span {
    font-size: 8px;

    @include respond (gt-md) {
      font-size: 12px;
    }
  }
}

//text-orientation

.v-block {
  font-size: 8px;

  @include respond(gt-sm) {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  span {
    font-size: 8px;

    @include respond (gt-sm) {
      font-size: 12px;
    }
  }
}

//hagag awards block

.awards {
  background-color: $husk;
  padding-top: 40px;
  padding-bottom: 40px;

  h5 {
    @include respond(gt-xs) {
      width: 55%;
    }
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    margin: 0 auto;
  }

  ul {
    width: max-content;
    margin: 0 auto;

    li {
      margin-bottom: 21px;
      font-size: 16px;
      line-height: 30px;
      margin-left: 50px;
      position: relative;

      &::before {
        content: '';
        height: 1px;
        width: 25px;
        background: $white;
        position: absolute;
        left: 15px;
        top: 50%;
        margin-left: -50px;

        @include respond(gt-xs) {
          width: 35px;
          margin-left: -60px;
          left: 0;
        }
      }
    }
  }
}

.fs875 {
  font-size: 1.2rem !important;
  line-height: 30px;
}

.c-black {
  color: $black;
}

.fs30 {
  font-size: 30px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs24 {
  font-size: 24px !important;
}

.p-proj {
  width: 100%;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  text-align: justify;

  @include respond(gt-xs) {
    width: 70%;
  }
}

.bg-color--husk {
  background-color: $husk !important;
}
