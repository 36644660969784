.fa-3d {
  font-size: 90px;
	transform-style: preserve-3d;
	perspective: 1000px;
	animation-name: gongon;
	animation-duration: 10s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}

@keyframes gongon {
  0% {
    // text-shadow: 1px 1px 1px $white;
    transform: rotateY(0deg);
  }

  25% {
    // text-shadow: 1px 1px 1px $white, -2px 1px 1px $white, -3px 1px 1px $white, -4px 1px 1px $white, -4px 1px 1px $white,
    // -5px 1px 1px $white, -6px 1px 1px $white, -7px 1px 1px $white, -8px 1px 1px $white, -9px 1px 1px $white, -10px 1px 1px $white,
    // -11px 1px 1px $white, -12px 1px 1px $white, -13px 1px 1px $white, -14px 1px 1px $white, -15px 1px 1px $white, -16px 1px 1px $white,
    // -17px 1px 1px $white, -18px 1px 1px $white, -19px 1px 1px $white, -20px 1px 1px $white;
    transform: rotateY(40deg);
  }

  50% {
    // text-shadow: 0 0 0 $white;
    transform: rotateY(0deg);
  }

  75% {
    // text-shadow: 1px 1px 1px $white,  2px 1px 1px $white,  3px 1px 1px $white,  4px 1px 1px $white, 4px 1px 1px $white,
    // 5px 1px 1px $white, 6px 1px 1px $white, 7px 1px 1px $white, 8px 1px 1px $white, 9px 1px 1px $white, 10px 1px 1px $white,
    // 11px 1px 1px $white, 12px 1px 1px $white, 13px 1px 1px $white, 14px 1px 1px $white, 15px 1px 1px $white, 16px 1px 1px $white,
    // 17px 1px 1px $white, 18px 1px 1px $white, 19px 1px 1px $white, 20px 1px 1px $white;
    transform: rotateY(-40deg);
  }

  100% {
    // text-shadow: 1px 1px 1px $white;
    transform: rotateY(0deg);
  }
}
